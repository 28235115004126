import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import { Paper, Table, TableBody, TableContainer, TableHead, TableRow, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StyledCell } from '../StyledCell';
import { ContentPermissions } from '../../../types/User';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.custom.whiteBackground,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export const RightsTable:React.FC = () => {

  const { t } = useTranslation(['main']);
  const { user } = useAuth();
  const [rights, setRights] = useState<ContentPermissions[]>();

  useEffect(() => {
    if(user) {
      setRights(user?.data.permissions[0].permissions);
    }
  }, [user]);

  return (
    <Paper elevation={2}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledCell text={t('main:users.userRights')} />
            </TableRow>
          </TableHead>
          <TableBody>
            { rights && rights.map((permission: ContentPermissions) => (
              <StyledTableRow key={`keyRow-${permission.id}`} >
                <StyledCell key={`keyCell-${permission.id}`} text={permission.display_name} />
              </StyledTableRow>))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
