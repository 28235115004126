import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import theme from '../../theme';
import { QuickFilters } from './QuickFilters';
import { FilterResultsWrapper } from './FilterResultsWrapper';
import { QuickSearch } from './QuickSearch';
import { MainFilters, MainFilterOption } from './MainFilters';
import ClientService from '../../services/ClientService';
import { AllProductsData } from '../../types/AllProductsData';
import {
  QuickFiltersData,
  MainFiltersType,
  Filters,
} from '../../types/Filters';
import { useAuth } from '../../contexts/AuthContext';

const fetchAllProducts = (
  page: number,
  face?: string | null,
  glue?: string | null,
  back?: string | null,
  services?: string | null,
  certificates?: string | null,
  product_back?: string | null,
  product_glue?: string | null,
  product_face?: string | null,
  supplier?: string | null,
) => {
  try {
    return ClientService.getAllProducts(
      page,
      face,
      glue,
      back,
      services,
      certificates,
      product_back,
      product_glue,
      product_face,
      supplier,
    );
  } catch (e) {
    console.log('Error getting all products list');
  }
};

const fetchFilters = () => {
  try {
    return ClientService.getAllFilters();
  } catch (e) {
    console.log('Error getting all products list');
  }
};

const intersect = (a: string[], b: string[]) => {
  const setB = new Set(b);
  return a?.filter((el: string) => setB.has(el));
};

export const SearchPage: React.FC = () => {
  const { t } = useTranslation(['main']);
  const { lang } = useAuth();
  const [allProductsData, setAllProductsData] = useState<
    AllProductsData | undefined
  >();
  const [displayedProductsData, setDisplayedProductsData] = useState<
      AllProductsData | undefined
  >();
  const [allFiltersData, setAllFiltersData] = useState<Filters>();
  const [pageNumber, setPageNumber] = useState(1);
  const [quickFiltersData, setQuickFiltersData] = useState<
    QuickFiltersData | undefined
  >();
  const [mainFiltersData, setMainFiltersData] = useState<
    MainFiltersType | undefined
  >();

  // Quick filters
  const [faceValue, setFaceValue] = useState<string[]>([]);
  const [glueValue, setGlueValue] = useState<string[]>([]);
  const [backValue, setBackValue] = useState<string[]>([]);
  const [certificatesValue, setCertificatesValue] = useState<string[]>([]);
  const [servicesValue, setServicesValue] = useState<string[]>([]);

  // Main filters
  const [faceProductValue, setFaceProductValue] =
    useState<MainFilterOption | null>(null);
  const [glueProductValue, setGlueProductValue] =
    useState<MainFilterOption | null>(null);
  const [backProductValue, setBackProductValue] =
    useState<MainFilterOption | null>(null);
  const [supplierProductValue, setSupplierProductValue] =
    useState<MainFilterOption | null>(null);

  // Main filter combos
  const [backFace, setBackFace] = useState<any>();
  const [backGlue, setBackGlue] = useState<any>();
  const [faceBack, setFaceBack] = useState<any>();
  const [faceGlue, setFaceGlue] = useState<any>();
  const [glueBack, setGlueBack] = useState<any>();
  const [glueFace, setGlueFace] = useState<any>();

  // Pagination page number change
  const handlePageChange = (value: number) => setPageNumber(value);

  // All filters data
  useEffect(() => {
    // Quick & Main filters
    (async () => {
      const filtersData = await fetchFilters();

      setAllFiltersData(filtersData);
      setQuickFiltersData(filtersData?.data.filters);
      setMainFiltersData(filtersData?.data?.suppliers['UPM Raflatac Oy'].product_groups);
      setBackFace(filtersData?.data?.backFace);
      setBackGlue(filtersData?.data?.backGlue);
      setFaceBack(filtersData?.data?.faceBack);
      setFaceGlue(filtersData?.data?.faceGlue);
      setGlueBack(filtersData?.data?.glueBack);
      setGlueFace(filtersData?.data?.glueFace);
    })();
  }, [lang]);

  useEffect(() => {
    const filtersActive =
        faceProductValue?.value ||
        glueProductValue?.value ||
        backProductValue?.value ||
        supplierProductValue?.value ||
        faceValue.length ||
        glueValue.length ||
        backValue.length ||
        servicesValue.length ||
        certificatesValue.length;

    if (filtersActive) {
      (async () => {
        const productsData = await fetchAllProducts(
          1,
          faceValue?.join(','),
          glueValue?.join(','),
          backValue?.join(','),
          servicesValue?.join(','),
          certificatesValue?.join(','),
          faceProductValue?.value,
          glueProductValue?.value,
          backProductValue?.value,
          supplierProductValue?.value,
        );
        // Create new references to ensure a re-render
        setDisplayedProductsData(productsData);
      })();
    } else {
      (async () => {
        const productsData = await fetchAllProducts(1);
        setDisplayedProductsData(productsData);
      })();
    }
  }, [
    faceProductValue,
    glueProductValue,
    backProductValue,
    supplierProductValue,
    faceValue,
    glueValue,
    backValue,
    servicesValue,
    certificatesValue,
    pageNumber,
  ]);

  useEffect(() => {
    (async () => {
      const productsData = await fetchAllProducts(1);
      setAllProductsData(productsData);
    })();
  }, []);

  // On page number change
  useEffect(() => {
    (async () => {
      if (pageNumber > 1) {
        const productsData = await fetchAllProducts(
          pageNumber,
          faceValue?.join(','),
          glueValue?.join(','),
          backValue?.join(','),
          servicesValue?.join(','),
          certificatesValue?.join(','),
          faceProductValue?.value,
          glueProductValue?.value,
          backProductValue?.value,
          supplierProductValue?.value,
        );
        setAllProductsData(productsData);
        setDisplayedProductsData(productsData);
      }
    })();
  }, [pageNumber]);

  // On change main filter 'Producer', change the other 3 filters

  useEffect(() => {
    if(supplierProductValue?.label) {
      const supplierValue = supplierProductValue.label as 'Fedrigoni' | 'UPM Raflatac Oy';
      setMainFiltersData(allFiltersData?.data?.suppliers[supplierValue].product_groups);
    } else {
      setMainFiltersData(allFiltersData?.data?.suppliers['UPM Raflatac Oy'].product_groups);
    }
  }, [supplierProductValue]);

  // On change main filter 'FACE', change the other 2 filters
  useEffect(() => {
    if (faceProductValue) {
      if (mainFiltersData) {
        let newGlue = [];
        let newBack = [];

        if (glueProductValue || backProductValue) {
          if (glueProductValue) {
            // faceBack
            const backOptsByFace = faceBack[faceProductValue.value];
            // glueBack
            const backOptsByGlue = glueBack[glueProductValue.value];

            const backOptsMatch = intersect(backOptsByFace, backOptsByGlue);

            if (backOptsMatch && backOptsMatch.length > 0) {
              newBack = backOptsMatch;

              setMainFiltersData({
                ...mainFiltersData,
                back: newBack,
              });
            }
          }

          if (backProductValue) {
            // faceGlue
            const glueOptsByFace = faceGlue[faceProductValue.value];
            // backGlue
            const glueOptsByBack = backGlue[backProductValue.value];

            const glueOptsMatch = intersect(glueOptsByFace, glueOptsByBack);

            if (glueOptsMatch && glueOptsMatch.length > 0) {
              newGlue = glueOptsMatch;

              setMainFiltersData({
                ...mainFiltersData,
                glue: newGlue,
              });
            }
          }
        } else {
          //faceGlue
          newGlue = faceGlue[faceProductValue.value];
          //faceBack
          newBack = faceBack[faceProductValue.value];

          if (newGlue && newBack) {
            setMainFiltersData({
              ...mainFiltersData,
              glue: newGlue,
              back: newBack,
            });
          }
        }
      }
    } else {
      // if other main filters are also empty, reset all filters
      if (!glueProductValue && !backProductValue) {
        const supplierValue = supplierProductValue?.label as 'Fedrigoni' | 'UPM Raflatac Oy';
        setMainFiltersData(allFiltersData?.data.suppliers[supplierValue || 'UPM Raflatac Oy'].product_groups);
      }
    }
  }, [faceProductValue]);

  // On change main filter 'GLUE', change the other 2 filters
  useEffect(() => {
    if (glueProductValue) {
      if (mainFiltersData) {
        let newFace = [];
        let newBack = [];

        if (faceProductValue || backProductValue) {
          if (faceProductValue) {
            // faceBack
            const backOptsByFace = faceBack[faceProductValue.value];

            // glueBack
            const backOptsByGlue = glueBack[glueProductValue.value];

            const backOptsMatch = intersect(backOptsByGlue, backOptsByFace);

            if (backOptsMatch && backOptsMatch.length > 0) {
              newBack = backOptsMatch;

              setMainFiltersData({
                ...mainFiltersData,
                back: newBack,
              });
            }
          }
          if (backProductValue) {
            // glueFace
            const faceOptsByGlue = glueFace[glueProductValue.value];
            // backFace
            const faceOptsByBack = backFace[backProductValue.value];

            const faceOptsMatch = intersect(faceOptsByGlue, faceOptsByBack);

            if (faceOptsMatch && faceOptsMatch.length > 0) {
              newFace = faceOptsMatch;

              setMainFiltersData({
                ...mainFiltersData,
                face: newFace,
              });
            }
          }
        } else {
          // glueBack
          newBack = glueBack[glueProductValue.value];
          // glueFace
          newFace = glueFace[glueProductValue.value];

          setMainFiltersData({
            ...mainFiltersData,
            face: newFace,
            back: newBack,
          });
        }
      }
    } else {
      // if other main filters are also empty, reset all filters
      if (!faceProductValue && !backProductValue) {
        const supplierValue = supplierProductValue?.label as 'Fedrigoni' | 'UPM Raflatac Oy';
        setMainFiltersData(allFiltersData?.data.suppliers[supplierValue || 'UPM Raflatac Oy'].product_groups);
      }
    }
  }, [glueProductValue]);

  // On change main filter 'BACK', change the other 2 filters
  useEffect(() => {
    if (backProductValue) {
      if (mainFiltersData) {
        let newFace = [];
        let newGlue = [];

        if (faceProductValue || glueProductValue) {
          if (faceProductValue) {
            // faceGlue
            const glueOptsByFace = faceGlue[faceProductValue.value];
            // backGlue
            const glueOptsByBack = backGlue[backProductValue.value];

            const glueOptsMatch = intersect(glueOptsByBack, glueOptsByFace);

            if (glueOptsMatch && glueOptsMatch.length > 0) {
              newGlue = glueOptsMatch;

              setMainFiltersData({
                ...mainFiltersData,
                glue: newGlue,
              });
            }
          }
          if (glueProductValue) {
            // backFace
            const faceOptsByBack = backFace[backProductValue.value];

            // glueFace
            const faceOptsByGlue = glueFace[glueProductValue.value];

            const faceOptsMatch = intersect(faceOptsByBack, faceOptsByGlue);

            if (faceOptsMatch && faceOptsMatch.length > 0) {
              newFace = faceOptsMatch;

              setMainFiltersData({
                ...mainFiltersData,
                face: newFace,
              });
            }
          }
        } else {
          //backFace
          newFace = backFace[backProductValue.value];
          //backGlue
          newGlue = backGlue[backProductValue.value];

          setMainFiltersData({
            ...mainFiltersData,
            face: newFace,
            glue: newGlue,
          });
        }
      }
    } else {
      // if other main filters are also empty, reset all filters
      if (!faceProductValue && !glueProductValue) {
        const supplierValue = supplierProductValue?.label as 'Fedrigoni' | 'UPM Raflatac Oy';
        setMainFiltersData(allFiltersData?.data.suppliers[supplierValue || 'UPM Raflatac Oy'].product_groups);
      }
    }
  }, [backProductValue]);

  return (
    <Box>
      <Box
        display="flex"
        flexDirection="column"
        maxWidth={theme.custom.maxContentPageWidth}
        mx="auto"
        justifyContent="center"
        gap={theme.spacing(5)}
        p={theme.spacing(5, 0)}
        sx={{
          paddingX: {
            xs: '10px',
            md: '0',
          },
        }}
      >
        <Box
          mx="auto"
          sx={{
            width: {
              xs: '100%',
              sm: '300px',
            },
          }}
        >
          <QuickSearch />
        </Box>
        <Typography variant="h2" textAlign="center">
          {t('main:labelDetails.title')}
        </Typography>

        {mainFiltersData && (
          <MainFilters
            mainFiltersData={mainFiltersData}
            onChangeFaceValue={(value: MainFilterOption | null) =>
              setFaceProductValue(value)}
            onChangeGlueValue={(value: MainFilterOption | null) =>
              setGlueProductValue(value)}
            onChangeBackValue={(value: MainFilterOption | null) =>
              setBackProductValue(value)}
            onChangeSupplierValue={(value: MainFilterOption | null) =>
              setSupplierProductValue(value)}
            faceValue={faceProductValue}
            glueValue={glueProductValue}
            backValue={backProductValue}
            supplierValue={supplierProductValue}
          />
        )}

        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12} md={3}>
            {quickFiltersData && (
              <QuickFilters
                quickFiltersData={quickFiltersData}
                onChangeFaceValue={(value: string[]) => setFaceValue(value)}
                onChangeGlueValue={(value: string[]) => setGlueValue(value)}
                onChangeBackValue={(value: string[]) => setBackValue(value)}
                onChangeServicesValue={(value: string[]) =>
                  setServicesValue(value)}
                onChangeCertificatesValue={(value: string[]) =>
                  setCertificatesValue(value)}
                faceValue={faceValue}
                glueValue={glueValue}
                backValue={backValue}
                certificatesValue={certificatesValue}
                servicesValue={servicesValue}
              />
            )}
          </Grid>
          <Grid item={true} xs={12} md={9}>
            { displayedProductsData?.data.length
              ? (
                <FilterResultsWrapper
                  allProductsData={displayedProductsData}
                  onPageChange={handlePageChange}
                /> )
              : (
                <FilterResultsWrapper
                  allProductsData={allProductsData}
                  onPageChange={handlePageChange}
                />
              )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
