import React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['main']);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '60vh',
      }}
    >
      <p style={{ fontStyle: 'italic' }}>
        {t('notFound.message')}
      </p>
      <Button variant="actionButton" onClick={() => navigate('/')}>
        {t('notFound.redirect')}
      </Button>
    </div>
  );
};
