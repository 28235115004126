import React from 'react';
import { StyledCell } from './StyledCell';

interface Heads {
  heads: string[];
}

export const TableHeadRow = ({ heads }: Heads) => (
  <>
    {heads.map((head, index) => (
      <StyledCell key={`Key${index}`} text={head} />
    ))}
  </>
);
