import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import theme from '../../theme';

type OffcutProps = {
  netWidthService?: boolean;
  offcut: number;
  totalPrice: number;
  total: number;
  rollTotal: number;
  productPrice: number;
  priceChanged?: boolean;
  oldPrice?: string;
  newPrice?: string;
}


export const Offcut: React.FC<OffcutProps> = ({
  netWidthService,
  offcut,
  totalPrice,
  total,
  rollTotal,
  productPrice,
  priceChanged,
  oldPrice,
  newPrice,
}) => {

  const { t } = useTranslation(['main']);

  return (
    <Grid container item direction={'column'} spacing={2} xs={9}>
      <Grid container item>
        <Grid item xs={8}>
          <Typography variant='body3Dark'>
            {!netWidthService && `${t('main:configurationPage.offcut')}: ${offcut?.toLocaleString('de-DE')} ${t('main:configurationPage.mm')}`}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant='body3Dark'>
            {`${rollTotal} ${t('main:configurationPage.rolls')}`}
          </Typography>
        </Grid>
      </Grid>
      <Grid container item>
        <Grid
          item
          xs={4}
          sx={{
            marginRight: {
              xs: '5px',
              sm: '0',
            },
          }}
        >
          <Typography variant='body3Dark'>
            {`${t('main:configurationPage.inTotal')}: ${total.toLocaleString('de-DE')} ${t('main:configurationPage.qm')}`}
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            marginX: {
              xs: '5px',
              sm: '0',
            },
          }}
        >
          <Typography variant='body3Dark' style={priceChanged ? { color: theme.palette.primary.red, textDecoration: 'line-through' } : {}}>
            {`${(productPrice)?.toLocaleString('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 4 })}/${t('main:configurationPage.qm')}`}
          </Typography>
          {priceChanged && newPrice &&
            <Typography variant='body3Dark'>
              {`${(newPrice)?.toLocaleString()}/${t('main:configurationPage.qm')}`}
            </Typography>}
        </Grid>
        <Grid
          item
          xs={4}
        >
          <Typography
            variant='body3Dark'
            sx={{
              marginY: {
                xs: '5px',
                sm: '0',
              },
            }}
          >
            {`${(totalPrice).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}`}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};


