import { NinoxDataRow } from '../types/Ninox';

class NinoxService {
  static async fetchColumns () {
    const response = await fetch(
      `${process.env.REACT_APP_NINOX_BASE_URL}/teams/${process.env.REACT_APP_NINOX_TEAM_ID}/databases/${process.env.REACT_APP_NINOX_DATABASE_ID}/tables`,
      {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_NINOX_ACCESS_TOKEN}`,
          'Content-Type': 'application/json',
        },
      },
    );

    const teams = await response.json();
    return teams;
  }

  static async fetchDataForTable (id: string): Promise<NinoxDataRow[]> {
    const response = await fetch(
      `${process.env.REACT_APP_NINOX_BASE_URL}/teams/${process.env.REACT_APP_NINOX_TEAM_ID}/databases/${process.env.REACT_APP_NINOX_DATABASE_ID}/tables/${id}/records`,
      {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_NINOX_ACCESS_TOKEN}`,
          'Content-Type': 'application/json',
        },
      },
    );

    const data: NinoxDataRow[] = await response.json();
    return data;
  }
}

export default NinoxService;
