import React, { useEffect, useState } from 'react';
import { Box, SxProps, Theme } from '@mui/material';
import { getCookie, setCookie } from '../utils/genericFunctions';
import { DropdownWrapper } from './DropdownWrapper';
import { DEFAULT_LOCALE } from '../contexts/ApiContext';
import { useTranslation } from 'react-i18next';
import ClientService from '../services/ClientService';
import { Locale } from './interfaces';
import { useAuth } from '../contexts/AuthContext';

const setNewLocale = async (locale: Locale) => {
  try {
    return ClientService.setLocale(locale);
  } catch (e) {
    console.log('Error setting language');
  }
};

const languages = [
  {
    name: 'EN',
    value: 'en',
  },
  {
    name: 'DE',
    value: 'de',
  },
];

type Props = {
  sx?: SxProps<Theme>;
};

export const LanguageDropdown: React.FC<Props> = ({ sx }) => {
  const { i18n } = useTranslation();
  const { user, lang, setLang, setUser } = useAuth();
  const [selectedLanguage, setSelectedLanguage] = useState(
    getCookie('locale') ?? DEFAULT_LOCALE,
  );

  const onLangSwitchChange = (newLocale: string) => {
    setSelectedLanguage(newLocale);
    i18n.changeLanguage(newLocale);
    setCookie('locale', newLocale, 3600 * 24);

    if (user) {
      const localeData = { locale: newLocale };
      (async () => {
        const userInfo = await setNewLocale(localeData);
        const newUser = await ClientService.getProfileInfo();
        if (userInfo && newUser) {
          setLang(newLocale);
          setUser({ ...user, data: { ...newUser.data, userInfo: userInfo } });
        }
      })();
    }
  };

  useEffect(() => {
    if (user && lang) {
      setSelectedLanguage(lang);
      i18n.changeLanguage(lang);
      setCookie('locale', lang, 3600 * 24);
    }
  }, [user, lang]);

  return (
    <Box sx={sx}>
      <DropdownWrapper
        data={languages}
        onChange={onLangSwitchChange}
        initialValue={selectedLanguage}
      />
    </Box>
  );
};
