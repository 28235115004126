import React, { useEffect, useState } from 'react';
import { FormControl, Grid } from '@mui/material';
import { MainFiltersType } from '../../types/Filters';
import theme from '../../theme';
import { useTranslation } from 'react-i18next';
import { FilterSelect } from './FilterSelect';
import { useAllSuppliers } from '../../selectors/selectors';
import { SingleSupplier } from '../../types/Suppliers';

export type MainFilterOption = {
    label: string,
    value: string
}

type MainFilter = {
    key: string
    options: MainFilterOption[]
}

type Props = {
  mainFiltersData: MainFiltersType,
  onChangeFaceValue: (value: MainFilterOption | null) => void,
  onChangeGlueValue: (value: MainFilterOption | null) => void,
  onChangeBackValue: (value: MainFilterOption | null) => void,
  onChangeSupplierValue: (value: MainFilterOption | null) => void,
  faceValue: MainFilterOption | null,
  glueValue: MainFilterOption | null,
  backValue: MainFilterOption | null,
  supplierValue: MainFilterOption | null,
}

export const MainFilters: React.FC<Props> = ( props: Props ) => {
  const { t } = useTranslation(['main']);
  const { data: suppliersData } = useAllSuppliers();
  const {
    mainFiltersData,
    onChangeFaceValue,
    onChangeGlueValue,
    onChangeBackValue,
    onChangeSupplierValue,
    faceValue,
    glueValue,
    backValue,
    supplierValue } = props;
  const [availableFilters, setAvailableFilters] = useState<MainFilter[]>([]);

  useEffect(() => {
    if (mainFiltersData) {
      setAvailableFilters([]);
      Object.entries(mainFiltersData).forEach(option => {
        const newFilters:MainFilter = {
          key: option[0],
          options: [],
        };
        option[1].forEach(opt => {
          newFilters.options.push({
            label: opt,
            value: opt,
          });
        });
        setAvailableFilters(prevAvailableFilters => [...prevAvailableFilters, newFilters]);
      });
    }
  }, [mainFiltersData]);


  return (
    <Grid
      container={true}
      spacing={2}
      justifyContent='flex-end'
      sx={{
        display: 'flex',
        flexDirection: {
          xs: 'column',
          sm: 'row',
        },
      }}
    >
      {suppliersData &&
        <Grid
          key={'index'}
          item={true}
          xs={12}
          sm={3}
        >
          <FormControl
            fullWidth={true}
            sx={{
              backgroundColor: theme.custom.whiteBackground,
            }}
          >
            <FilterSelect
              key={'supplier'}
              name={'supplier'}
              placeholder={t('main:filters.producer')}
              options={suppliersData.map((s: SingleSupplier) => ({
                label: s.name,
                value: `${s.id}`,
              }))}
              value={supplierValue}
              onChangeSupplierValue={onChangeSupplierValue}
            />
          </FormControl>
        </Grid>}

      {availableFilters.map((filter, index) => (
        <Grid key={index} item xs={12} sm={3}>
          <FormControl
            fullWidth
            sx={{ backgroundColor: theme.custom.whiteBackground }}
          >
            <FilterSelect
              key={`product_${filter.key}`}
              name={filter.key}
              placeholder={t(`main:filters.${filter.key}`) ?? ''}
              options={
                filter.key === 'glue'
                  ? [...filter.options].sort((a, b) =>
                    a.label.localeCompare(b.label),
                  )
                  : filter.options
              }
              value={
                filter.key === 'face'
                  ? faceValue
                  : filter.key === 'glue'
                    ? glueValue
                    : filter.key === 'back'
                      ? backValue
                      : null
              }
              onChangeFaceValue={onChangeFaceValue}
              onChangeGlueValue={onChangeGlueValue}
              onChangeBackValue={onChangeBackValue}
            />
          </FormControl>
        </Grid>
      ))}
    </Grid>
  );
};


