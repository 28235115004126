import React, { useEffect, useState } from 'react';
import { Box, Pagination, Typography } from '@mui/material';
import theme from '../../theme';
import { FilteredResult } from './FilteredResult';
import { AllProductsData } from '../../types/AllProductsData';
import { useTranslation } from 'react-i18next';

type Props = {
  allProductsData: AllProductsData | undefined,
  onPageChange: (value: number) => void,
}

export const FilterResultsWrapper: React.FC<Props> = props => {
  const { t } = useTranslation(['main']);
  const { allProductsData, onPageChange } = props;
  const [maxPageNumber, setMaxPageNumber] = useState<number | undefined>(0);

  const hanglePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    onPageChange(value);
  };

  useEffect(() => {
    setMaxPageNumber(allProductsData?.meta?.last_page);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [allProductsData]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      gap={theme.spacing(2)}
      width="100%"
    >
      {allProductsData?.data?.map((labelInfo, index) => (
        <FilteredResult
          key={`${labelInfo.id}-${index}`}
          data={labelInfo}
        />
      ))}
      {allProductsData?.data && allProductsData?.data?.length > 0 ?
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          gap={theme.spacing(2)}
          width='100%'
        >
          <Pagination
            count={maxPageNumber}
            boundaryCount={2}
            size="small"
            shape="rounded"
            onChange={hanglePageChange}
            sx={{
              button: {
                color: theme.custom.footerColorSecondary,
              },
              '& .Mui-selected': {
                backgroundColor: theme.custom.borderGrey,
              },
              '& .MuiPaginationItem-ellipsis': {
                color: theme.custom.footerColorSecondary,
              },
            }}
          />
        </Box>
        :
        <Typography variant="h6">
          {t('main:quickSearch.noProductsFound')}
        </Typography>}
    </Box>
  );
};
