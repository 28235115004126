/* eslint-disable react/jsx-closing-tag-location */
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import {
  Alert,
  Box, Divider, Grid,
  IconButton,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Product, ProductService } from '../../types/Product';
import theme from '../../theme';
import { CalculatorTableRow } from './CalculatorTableRow';
import { StyledTableCell } from '../../ui-components/StyledTableSell';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { Button } from '../../ui-components/Button';
import { RowData, SetTotals, SaveData, Totals } from './interfaces';
import ProductTableRow from './ProductTableRow';
import { useNavigate } from 'react-router-dom';
import { Dayjs } from 'dayjs';
import ClientService from '../../services/ClientService';
import { RestrictedComponent } from '../../ui-components/RestrictedComponent';
import { Offcut } from './Offcut';
import { OffcutArticle } from './OffcutArticle';
import { SingleOrder } from '../../types/Orders';

type ProductCalculatorProps = {
  row?: SingleOrder,
  setId?: string | number;
  product: Product | undefined
  palService: ProductService | undefined;
  deliveryDate?: Dayjs | null;
  productTableRowsOrder: ProductTableRow[];
  buttonText: string;
  hideSaveButton?: boolean;
  hideCancelButton?: boolean;
  hideAddRowButton?: boolean;
  disabledInputs?: boolean;
  orderId?: number | null;
  isInOrders?: boolean;
  numberOfRolls?: number;
  totalsForSet?: SetTotals,
  totalsFromOrdersRow?: Totals,
  offcutForUpdate?: number,
  onCancelArticle?: (value: number) => void;
  updateRowInSet?: (rows: ProductTableRow[]) => void;
  updateRowInOrder?: (rows: ProductTableRow[]) => void;
  setErrorMessages?: Dispatch<SetStateAction<string[] | null>> | undefined;
  refetchOrdersData?: () => void;
  ordersType?: string;
  priceChanged?: boolean;
  rowsWithCheck?: number[];
  setPriceChanged?: React.Dispatch<React.SetStateAction<boolean>> | undefined;
  setRowsWithCheck?: React.Dispatch<React.SetStateAction<number[]>>;
}

export const ProductCalculator: React.FC<ProductCalculatorProps> = ({
  row,
  setId,
  product,
  palService,
  deliveryDate,
  productTableRowsOrder,
  buttonText,
  hideSaveButton,
  hideCancelButton,
  hideAddRowButton,
  disabledInputs,
  orderId,
  isInOrders,
  numberOfRolls,
  totalsForSet,
  totalsFromOrdersRow,
  offcutForUpdate,
  ordersType,
  priceChanged,
  rowsWithCheck,
  onCancelArticle,
  updateRowInSet,
  updateRowInOrder,
  refetchOrdersData,
  setErrorMessages,
  setRowsWithCheck,
  setPriceChanged } ) => {
  const { t } = useTranslation(['main']);
  const navigate = useNavigate();
  const [productTableRows, setProductTableRows] = useState(productTableRowsOrder);
  const [total, setTotal] = useState<number>(0);
  const [rowCount, setRowCount] = useState<number>(5);
  const [orderWidth, setOrderWidth] = useState<number>(0);
  const [remainingWidth, setRemainingWidth] = useState(palService?.usable_width || 0);
  const [offcut, setOffcut] = useState(0);
  const [rollTotal, setRollTotal] = useState<number>(0);
  const [rollHeight, setRollHeight] = useState<number>(0);
  const [palLength, setPalLength] = useState<number>(0);
  const [productPrice, setProductPrice] = useState<number>(0);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState<string>(`${t('main:configurationPage.successfulAddOrder')}`);

  // const productPrices = useMemo(() => product?.prices?.filter(price => price.quantity <= total).pop(), [product, total]);
  // const currentProductPrice: number = useMemo(() => productPrices ? parseFloat(productPrices?.price) : 0, [productPrices]);
  const netWidthService = useMemo(() => !!palService?.net_wid_service, [palService]);
  const minOrderMeters = useMemo(() => {
    if (palService?.min_order && palService?.usable_width && !netWidthService){
      return Math.ceil(palService?.min_order / palService?.usable_width * 1000);
    }
  }, [palService, netWidthService]);

  const styleOfTableCell = {
    margin: '25px 55px 21px 15px',
    fontFamily: 'Heebo',
    fontSize: '14px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#454f5b',
    borderBlockEndColor: '#979797',
  };

  useEffect(() => {
    setPalLength(palService?.roll_length || 0);
    setTotal(0);

  }, [palService]);

  useEffect(() => {
    if(setErrorMessages) {
      setErrorMessages(null);
    }
    setRollTotal(0);
    setOrderWidth(0);
    setTotal(0);
    setProductPrice(0);
    setTotalPrice(0);
    setOffcut(0);
    setProductTableRows(productTableRowsOrder);
  }, []);

  useEffect(() => {
    if(totalsForSet !== undefined){
      setRollTotal(totalsForSet.totals.rolls_total);
      setOrderWidth(totalsForSet.totals.width_total);
      setTotal(totalsForSet.totals.total_sqm_all);
      setProductPrice(totalsForSet.totals.price);
      setTotalPrice(totalsForSet.totals.total_price);
      setOffcut(totalsForSet.totals.offcut);
    } else {
      setRollTotal(0);
      setOrderWidth(0);
      setTotal(0);
      setProductPrice(0);
      setTotalPrice(0);
      setOffcut(0);
    }
  }, [totalsForSet]);

  useEffect(() => {
    if(totalsFromOrdersRow !== undefined){
      setRollTotal(totalsFromOrdersRow.rolls_total);
      setOrderWidth(totalsFromOrdersRow.width_total);
      setTotal(totalsFromOrdersRow.total_sqm_all);
      setProductPrice(totalsFromOrdersRow.price);
      setTotalPrice(totalsFromOrdersRow.total_price);
      setOffcut(totalsFromOrdersRow.offcut);
    } else {
      setRollTotal(0);
      setOrderWidth(0);
      setTotal(0);
      setProductPrice(0);
      setTotalPrice(0);
      setOffcut(0);
    }
  }, [totalsFromOrdersRow]);

  useEffect(() => {
    if(productTableRowsOrder && productTableRowsOrder.length > 0){
      setProductTableRows(productTableRowsOrder);
    }
  }, [productTableRowsOrder]);

  const isButtonDisabled = useMemo(
    () => (!deliveryDate || !palService || !(total >= (palService.min_order || 0))
    ), [deliveryDate, palService, total]);

  useEffect(() => {
    setRollHeight(palLength ? palLength : minOrderMeters ? minOrderMeters : 0);
  }, [palService, palLength]);

  useEffect(() => {
    setRemainingWidth((palService?.usable_width || 0) - orderWidth );
  }, [orderWidth, palService]);

  const handleAddTableRows = () => {
    setProductTableRows([
      ...productTableRows,
      new ProductTableRow(rowCount + 1, '', 0, 0, 0, 0),
    ]);
    setRowCount(rowCount + 1);
  };

  const handleAddToCard = async () => {
    const cutting: any[] = [];

    productTableRows.map(row => {
      if (row?.rollCount > 0) {
        cutting.push(
          row?.id ? {
            id: row?.id,
            width: row?.rollWidth,
            rolls: row?.rollCount,
            meters: row?.rollHeight,
            material_number: row?.materialNumber,
            order_id: orderId,
          }
            : {
              width: row?.rollWidth,
              rolls: row?.rollCount,
              meters: row?.rollHeight,
              material_number: row?.materialNumber,
            });
      }
    });

    const data: SaveData = palService?.product_service_group.name !== 'MEGA' ? ({
      product_id: product?.id,
      service_id: palService?.id,
      supplier_id: product?.supplier?.id,
      material_number: '',
      delivery_date: deliveryDate?.format('YYYY-MM-DD'),
      sets: [
        {
          id: 1,
          offcut: offcutForUpdate,
          cutting: cutting,
        },
      ],
    }) : ({
      product_id: product?.id,
      service_id: palService?.id,
      supplier_id: product?.supplier?.id,
      material_number: '',
      delivery_date: deliveryDate?.format('YYYY-MM-DD'),
      sets: [
        {
          id: 1,
          cutting: cutting,
        },
      ],
    });

    if(orderId) {
      data.order_id = orderId;
    }

    try {
      if(orderId) {
        const responseUpdate = await ClientService.calculateAndUpdateCutting(data);
        setSnackBarMessage(`${t('main:configurationPage.successfulUpdateOrder')}`);
        const updatedRows: any[] = [];

        responseUpdate.items.forEach((item: any) => {
          const updatedRowItem = new ProductTableRow(item.id, item.material_number, item.width, item.rolls, item.meters, item.total);
          updatedRows.push(updatedRowItem);
        });
        setProductTableRows(updatedRows);
      } else {
        await ClientService.calculateAndSaveCutting(data);
      }
      setSnackBarMessage(`${t('main:configurationPage.successfulAddOrder')}`);
      setOpenSnackBar(true);
      if(refetchOrdersData) {
        refetchOrdersData();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleAcceptNewPrice = async () => {
    try {
      if(setPriceChanged && priceChanged && setRowsWithCheck && row && rowsWithCheck) {
        await handleAddToCard();
        setPriceChanged(!priceChanged);
        setRowsWithCheck(rowsWithCheck.filter((id: number) => id !== row.id));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancel = async () => {
    if(orderId) {
      try {
        const responseCancel = await ClientService.cancelProduct(orderId);
        if(responseCancel) {
          if(responseCancel.status === 'canceled') {
            setSnackBarMessage(`${t('main:configurationPage.successfulCancel')}`);
            setOpenSnackBar(true);
            if(onCancelArticle) {
              onCancelArticle(orderId);
            }
          }
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackBar(false);
    navigate('/orders');
  };

  const updateRow = (rowId: number, rowData: RowData) => {
    const index = productTableRows.findIndex(row => row.id === rowId);
    if (index !== -1) {
      const productTableRow = productTableRows[index];
      productTableRow.materialNumber = rowData.materialNumber;
      productTableRow.rollWidth = rowData.rollWidth;
      productTableRow.rollCount = rowData.rollCount;
      productTableRow.rollHeight = rowData.rollHeight;
      productTableRow.total = rowData.total;
      if(updateRowInSet){
        updateRowInSet(productTableRows);
      }
      if(updateRowInOrder){
        updateRowInOrder(productTableRows);
      }
    }
  };

  return (
    <Box>
      <TableContainer component={Paper} elevation={0}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow >
              <StyledTableCell
                sx={styleOfTableCell}
                colSpan={2}
              >
                {t('main:configurationPage.widthInMm')}
              </StyledTableCell>
              <StyledTableCell
                sx={styleOfTableCell}
              >
                {t('main:configurationPage.roll')}
              </StyledTableCell>
              <StyledTableCell
                sx={styleOfTableCell}
              >
                {t('main:configurationPage.linearMeters')}
              </StyledTableCell>
              <StyledTableCell
                sx={styleOfTableCell}
              >
                {t('main:configurationPage.orderNumber2')}
              </StyledTableCell>
              <StyledTableCell
                sx={styleOfTableCell}
              >
                {t('main:configurationPage.total')}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {productTableRows.map((row, index) => (
              <CalculatorTableRow
                key={`${row.id}_${index}`}
                id={row.id}
                rowNumber={index + 1}
                afterUpdate={updateRow}
                rowRollHeight={row.rollHeight}
                rollHeight={rollHeight}
                palLength={row.rollHeight ? palLength : 0}
                setRollHeight={setRollHeight}
                rollWidthOrder={row.rollWidth}
                rollsCountOrder={row.rollCount}
                materialNumberOrder={row.materialNumber ?? ''}
                totalOrder={row.total}
                disabledInputs={disabledInputs}
                isInOrders={isInOrders}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Divider color={theme.custom.dividerColor} />
      {!hideAddRowButton &&
        <IconButton onClick={handleAddTableRows} sx={{ marginTop: '5px' }}>
          <AddBoxOutlinedIcon />
        </IconButton>}
      { palService?.product_service_group.name !== 'MEGA' && product && !isInOrders && setId && <TableContainer component={Paper} elevation={0}>
        <Table sx={{ minWidth: 650, backgroundColor: 'rgba(248, 4, 4, 0.24)' }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  borderBottom: 'none',
                  padding: '0',
                }}
              >
                <Typography
                  variant='body3Dark'
                  sx={{
                    marginLeft: '4rem',
                    marginTop: '0.5rem',
                    fontFamily: 'Heebo',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: 'normal',
                    letterSpacing: 'normal',
                    color: '#454f5b',
                  }}
                >
                  {t('main:configurationPage.offcut')}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <OffcutArticle
              key={setId}
              offcut={offcut}
              rollHeight={rollHeight}
              totalSQM={total}
              productPrice={productPrice}
              numberOfRolls={numberOfRolls ? numberOfRolls : 1}
            />
          </TableBody>
        </Table>
      </TableContainer>}
      {!isInOrders && product && numberOfRolls && <Grid container direction='row' sx={{ display: 'flex', justifyContent: 'flex-end' }} gap='3rem'>
        <Typography variant='body3Dark' sx={{ marginY: '1rem' }}>
          {`${t('main:configurationPage.total')}: ${(total).toLocaleString('de-DE')} ${t('main:configurationPage.qm')}`}
        </Typography>
        <Typography variant='body3Dark' sx={{ marginY: '1rem', fontSize: '20px' }}>
          {`${totalPrice.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}`}
        </Typography>
      </Grid>}
      <Grid
        container
        sx={{
          flexDirection: {
            xs: 'column',
            sm: 'row',
          },
          minWidth: 750,
        }}
      >
        {isInOrders && ordersType === 'new' ?
          <Offcut
            netWidthService={netWidthService}
            offcut={offcut}
            totalPrice={totalPrice}
            total={total}
            rollTotal={rollTotal}
            productPrice={productPrice}
            priceChanged={priceChanged}
            oldPrice={row?.order_product?.old_price}
            newPrice={row?.order_product?.price}
          /> : row &&
            <Offcut
              offcut={row.offcut}
              totalPrice={row.total_price}
              total={row.total_sqm_all}
              rollTotal={row.rolls_total}
              productPrice={row.price}
            />}
        <Grid
          item
          xs={8}
          md={3}
          sx={{
            marginTop: {
              xs: '15px',
              md: '0',
            },
          }}
        >
          {/* create_orders || edit_shopping_cart */}
          <RestrictedComponent permission='create_orders'>
            {priceChanged &&
              <Button
                variant="actionButtonGreen"
                onClick={handleAcceptNewPrice}
                disabled={isButtonDisabled}
              >
                {t('main:orders.update')}
              </Button>}
          </RestrictedComponent>
          <RestrictedComponent permission='create_orders'>
            {!hideSaveButton &&
              <Button
                variant="actionButtonGreen"
                onClick={handleAddToCard}
                disabled={isButtonDisabled}
              >
                {buttonText}
              </Button>}
          </RestrictedComponent>
          <RestrictedComponent permission='cancellation'>
            {!hideCancelButton &&
            <Button
              variant="actionButton"
              onClick={handleCancel}
              disabled={isButtonDisabled}
              sx={{ marginLeft: '10px' }}
            >
              {t('main:configurationPage.cancelBtnText')}
            </Button>}
          </RestrictedComponent>
        </Grid>
      </Grid>
      {openSnackBar &&
        <Snackbar
          open={openSnackBar}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert onClose={handleCloseSnackbar} variant="filled" severity="success" sx={{ width: '100%', backgroundColor: '#95cdb1' }}>
            {snackBarMessage}
          </Alert>
        </Snackbar>}
    </Box>
  );
};


