import React, { useState, useEffect } from 'react';
import './Wiki.css';
import { useTranslation } from 'react-i18next';
import NinoxService from '../../services/Ninox';
import { NinoxDataRow, NinoxField, NinoxCol } from '../../types/Ninox';

export default function Wiki () {
  const [activeTab, setActiveTab] = useState<number>(0);
  const [cols, setCols] = useState<NinoxCol[]>([]);
  // Store each table's data rows in an object keyed by team/table id.
  const [tableData, setTableData] = useState<Record<string, NinoxDataRow[]>>({});
  const [loading, setLoading] = useState<boolean>(true);
  const { t } = useTranslation(['main']);

  useEffect(() => {
    // First, fetch the table definitions (columns and team metadata)
    NinoxService.fetchColumns()
      .then((colsData: NinoxCol[]) => {
        setCols(colsData);
        // For each team, fetch the row data.
        return Promise.all(
          colsData.map(team =>
            NinoxService.fetchDataForTable(team.id).then((rows: NinoxDataRow[]) => ({
              teamId: team.id,
              rows,
            })),
          ),
        );
      })
      .then(results => {
        // Build an object mapping team id to its rows
        const dataMap: Record<string, NinoxDataRow[]> = {};
        results.forEach(({ teamId, rows }) => {
          dataMap[teamId] = rows;
        });
        setTableData(dataMap);
        setLoading(false);
      })
      .catch(err => {
        console.error('Error fetching columns or table data:', err);
        setLoading(false);
      });
  }, []);

  // Helper function to extract team name from fields array or directly from team.name
  const getTabName = (team: NinoxCol): string => {
    if (team.name) {
      const result = team.name.replace('KleberWiki ', '').trim();
      return result;
    }
    const nameField = team.fields.find((field: NinoxField) =>
      field.name.toLowerCase().includes('team') ||
        field.name.toLowerCase() === 'name',
    );
    return nameField && nameField.value ? String(nameField.value) : team.id || 'Unknown Team';
  };

  const showTab = (index: number) => {
    setActiveTab(index);
  };

  if (loading) {
    return (
      <div className="flex w-full h-full justify-center align-center">
        <p
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          {t('main:wiki.loading')}
        </p>
      </div>
    );
  }


  if (cols.length === 0) {
    return (
      <p className="no-data">
        {t('main:wiki.empty')}
      </p>
    );
  }

  return (
    <div className="wiki-container">
      <h1 className="wiki-title">
        {t('main:wiki.title')}
      </h1>

      {/* Tabs Navigation */}
      <div className="tabs">
        {cols.map((team, index) => (
          <div
            key={team.id}
            className={`tab ${activeTab === index ? 'active' : ''}`}
            onClick={() => showTab(index)}
            onKeyDown={e => (e.key === 'Enter' || e.key === ' ') && showTab(index)}
            role="button"
            tabIndex={0}
          >
            {getTabName(team)}
          </div>
        ))}
      </div>

      {/* Tab Content */}
      {cols.map((tab, index) => (
        <div key={tab.id} className={`tab-content ${activeTab === index ? 'active' : ''}`}>
          <h2>
            {getTabName(tab)}
          </h2>
          <div className="table-container">
            <table className="table-body">
              <thead>
                <tr className="table-col">
                  {tab.fields.map((field: NinoxField) => (
                    <th key={field.id} className="table-header">
                      {field.name}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                { tableData ? (
                  tableData[tab.id] && tableData[tab.id].length > 0 ? (
                    tableData[tab.id].map((row: NinoxDataRow, idx: number) => (
                      <tr key={idx}>
                        {tab.fields.map((field: NinoxField) => {
                          // Get the cell value from the row.fields using the field name
                          const cellValue = row.fields ? row.fields[field.name] : '';
                          // If cellValue is an object, try to use its "value" property,
                          // otherwise, convert it to string.
                          let displayValue;
                          if (typeof cellValue === 'object' && cellValue !== null) {
                            displayValue = cellValue.value || JSON.stringify(cellValue);
                          } else {
                            displayValue = cellValue;
                          }
                          return (
                            <td key={field.id} className="table-row">
                              {displayValue}
                            </td>
                          );
                        })}
                      </tr>
                    ))
                  ) : (
                    <tr className="table-col">
                      <td colSpan={tab.fields.length} className="no-data h-5">
                        <p className="container">
                          {t('main:wiki.empty')}
                        </p>
                      </td>
                    </tr>
                  )
                ) : (
                  <tr className="table-col">
                    <td colSpan={tab.fields.length} className="no-data">
                      {t('main:wiki.empty')}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      ))}
    </div>
  );
}
