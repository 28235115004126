import {
  Box,
  IconButton,
  Typography,
  Drawer,
  List,
  ListItem,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RestrictedComponent } from '../../ui-components/RestrictedComponent';
import MenuIcon from '@mui/icons-material/Menu';
import { useAuth } from '../../contexts/AuthContext';
import { NavigationListItem } from '../../ui-components/NavigationListItem';

const pages = [
  {
    pageTitle: 'navigation.article',
    link: '/article',
    permission: 'search_product',
  },
  {
    pageTitle: 'navigation.orders',
    link: '/orders',
    permission: 'read_company_shopping_cart',
  },
  {
    pageTitle: 'navigation.report',
    link: '/report',
    permission: 'create_reports',
  },
  {
    pageTitle: 'navigation.profile',
    link: '/profile',
    permission: 'edit_user_data',
  },
  {
    pageTitle: 'navigation.wiki',
    link: '/wiki',
    permission: 'search_product',
  },
  {
    pageTitle: 'navigation.contact',
    link: '/contact',
    permission: 'edit_user_data',
  },
  { pageTitle: 'navigation.faq', link: '/faq', permission: 'search_product' },
];

export const MobileNavigation: React.FC = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation(['main']);

  const [showMenu, setShowMenu] = useState<boolean>(false);

  const toggleMenu = () => {
    setShowMenu(showMenu => !showMenu);
  };

  const handleLogout = () => {
    if (user) {
      logout();
    }
    navigate('login');
  };

  return (
    <Box component='nav'>
      <IconButton
        size='large'
        aria-label='account of current user'
        aria-controls='menu-appbar'
        aria-haspopup='true'
        onClick={toggleMenu}
        color='inherit'
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        anchor='right'
        open={showMenu}
        onClose={toggleMenu}
      >
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={toggleMenu}
          onKeyDown={toggleMenu}
        >
          <List>
            {pages.map((page, index) => (
              page.permission ? (
                <RestrictedComponent key={index} permission={page.permission}>
                  <NavigationListItem page={page} />
                </RestrictedComponent>
              ) : (
                <NavigationListItem key={index} page={page} />
              )
            ))}
            <ListItem
              onClick={handleLogout}
              sx={{
                ml: 1,
              }}
            >
              <Typography variant='navigationLink'>
                {user ? t('buttons.logout') : t('buttons.login')}
              </Typography>
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </Box>
  );
};
